<template>
  <svg width="36" height="35" viewBox="0 0 36 35" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.25" y="0.25" width="0.5" height="28.5" stroke="#D9D9D9" stroke-width="0.5"/>
    <rect x="1.25" y="28.75" width="0.5" height="23.5" transform="rotate(-90 1.25 28.75)" stroke="#D9D9D9" stroke-width="0.5"/>
    <rect x="23" y="22" width="13" height="13" rx="4" fill="#D9D9D9"/>
  </svg>


</template>

<script>
export default {
  name: "svg_line_added"
}
</script>

<style scoped>

</style>