<template>
<div class="main-filter-bar">
  <div>
    <span>Afficher par <strong>mois </strong></span>
    <SelectInput v-model="month" :options="options" />
  </div>
    <span class="second-filter"> Trier par <span class=" filter-span"> <strong> Date de dernière action</strong></span> <icon-link class="icon-filter" name="filter" /></span>
</div>
</template>

<script>
import SelectInput from "../buttons/SelectInput";
import IconLink from "../../assets/icons/IconLink";
export default {
  name: "FilterBar",
  components: {IconLink, SelectInput},
  data(){
    return {
      month: 1,
      options: [
        { text: 'Janvier', value: 1 },
        { text: 'Février', value: 2 },
        { text: 'Mars', value: 3 },
        { text: 'Avril', value: 4 },
        { text: 'Mai', value: 5 },
        { text: 'Juin', value: 6 },
        { text: 'Juillet', value: 7 },
        { text: 'Août', value: 8 },
        { text: 'Septembre', value: 9 },
        { text: 'Octobre', value: 10 },
        { text: 'Novembre', value: 11 },
        { text: 'Décembre', value: 12 },
      ],
    }
  },
  watch: {
    month(newValue) {
      this.$emit('month', newValue);
    }
  }
}
</script>

<style scoped lang="scss">
@import "src/assets/variables/variables";
.main-filter-bar,
.second-filter{
  font-size: 12px;
  display: flex;
  align-items: center;
  grid-column: 1 / span 12;
  div{
    padding-right: 10px;
    border-right: 1px solid $color-border-bar;
  }
  .second-filter{
    padding-left: 5px;
  }

}

.main-filter-bar{
  margin: 0.5rem 1rem;
}

.icon-filter{
  margin-left: 0.5rem;
}

.filter-span{
  margin-left: 1rem;
}
</style>