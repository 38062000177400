<template>


    <div v-for="(charge) in data.charges" :key="charge.index" class="main-div-line">

      <div class="label-line" >
        <div class="name-label-line" >
          <div @click="setCurrent(charge.id), toogleDetails()">
            <Svg_line_solo class="svg-line-solo"/>
            {{ charge.label }}
          </div>
          <icon-link @click="openModal()" class="pencil-modification" v-if="charge.id === current" name="pencil" />
        </div>

        <ModalAdd  class="modal-charge" :open="openModalCharge" @close="openModalCharge = !openModalCharge">
          <span class="title-modal">Modification nom de la charge</span>
          <div>
            <input class="input-new-charge" type="text" :placeholder="charge.label" v-model="labelCharge">
            <base-button class="validate-button" @click="updateCharge(current)" button-name="Valider" />
          </div>
        </ModalAdd>


        <div class="amount-anticipated">
          <span disabled="true">0 €</span>
        </div>
        <div class="first-line-div">
          <div class="amount-real">
            <span>{{sumExpenses(charge.expenses)}} €</span>
          </div>
        </div>
      </div>
      <div v-if="charge.id === current">


        <div   v-for="(expense, indexExp) in charge.expenses" :key="expense.index" class="details-expenses">
          <div  class="second-div-line">
            <div  class="expense-line">
              <Svg_line_added class="svg-line-added"/>
              {{ expense.label }}
            </div>

            <div class="amount-expense-anticipated">0 €</div>
            <div  @click="setCurrentExpense(expense.id), openUpdate()" class="amount-expense-real">{{ expense.amount }} €</div>
            <input v-if="currentExpense === expense.id && updateDiv === true" class="add-anticipated-expense" disabled type="text" placeholder="0,00 €">
            <input v-if="currentExpense === expense.id && updateDiv === true" class="add-real-expense" type="text" @keyup.enter="updateExpense
            (expense.id, newAmount)" v-model.lazy="newAmount" :placeholder="expense.amount + ' €'">

            <div v-if="indexExp === charge.expenses.length - 1" class="new-data-div">
              <div class="new-expense">
                <Svg_line_adding class="svg-line-adding"/>
                <input class="input-expense" placeholder="Nouvelle dépense" v-model.lazy="expenses.label" type="text">
              </div>
              <input class="add-anticipated-expense" type="text" disabled placeholder="0,00 €">
              <input class="add-real-expense" type="text" @keyup.enter="expensesPost(expense.month, expense.charge_id)" v-model.lazy="expenses.amount" placeholder="0,00 €">
            </div>
          </div>

        </div>
      </div>

    </div>


</template>

<script>
import Svg_line_added from "../../assets/icons/svg_line_added";
import Svg_line_solo from "../../assets/icons/svg_line_solo";
import Svg_line_adding from "../../assets/icons/svg_line_adding";
import axios from "axios";
import {toRef} from "vue";
import IconLink from "../../assets/icons/IconLink.vue";
import ModalAdd from "../modals/ModalAdd.vue";
import BaseButton from "../buttons/BaseButton.vue";
import {mapGetters} from "vuex";

export default {
  name: "Line",
  components: {BaseButton, ModalAdd, IconLink, Svg_line_adding, Svg_line_solo, Svg_line_added},
  props: {
    data: {},
    month: null
  },
  setup(props){
    const dataRef = toRef(props, 'data')
    return {
      dataRef,
      'name': ''
    };
  },
  data() {
    return {
      newAmount: undefined,
      labelCharge: '',
      current: null,
      currentExpense: null,
      openDetails: false,
      updateDiv: false,
      openModalCharge: false,
      openModalExpense: false,
      expenses: {
        label: "",
        amount: undefined,
        year: 2020,
        month: 1,
        is_real: true,
        charge_id: 1
      }
    }
  },
  methods: {
    openUpdate(){
      this.updateDiv = !this.updateDiv;
      console.log(this.updateDiv)
    },
    updateCharge(id){
      console.log(this.labelCharge);
      console.log(id);
      axios.put(`http://localhost:8888/api/charges/${id}`, {label: this.labelCharge})
          .then( response => {
            this.$store.dispatch("updateChargeLabel", response.data)
          })
          .catch(error => console.log(error))
      console.log(this.month);
      this.openModalCharge = false;
    },
    openModal(){
      this.openModalCharge = true;
    },
    setCurrentExpense(id) {
      this.currentExpense = id;
      console.log(this.currentExpense)

    },
    setCurrent(id) {
      this.current = id;
      console.log(this.current)
    },
    sumExpenses(arr){
      let result = arr.reduce(function (acc, obj) { return acc + parseFloat(obj.amount); }, 0);
     return result},
    toogleDetails() {
      this.openDetails = !this.openDetails
    },
    updateExpense(id, amount){
      axios.put(`${process.env.VUE_APP_HOST_API_KARGA}expenses/${id}`, {amount: amount})
          .then( response => console.log(response), this.$store.dispatch("fetchCharges", this.month))
          .catch(error => console.log(error))
      this.$store.dispatch("fetchCharges", this.month);

    },
    expensesPost(month, charge_id){
      const newExpense = {
        label: this.expenses.label,
        amount: this.expenses.amount,
        year: 2020,
        month: month,
        is_real: true,
        charge_id: charge_id
      }
      axios.post(`${process.env.VUE_APP_HOST_API_KARGA}expenses/`, newExpense)
          .then( response => console.log(response))
          .catch(error => console.log(error))

      this.$store.dispatch("fetchCharges", this.month);
    },
},
  created() {
    // this.$store.dispatch("fetchCharges", this.month);
  },
  computed: {
    ...mapGetters([
      'getCharges'
    ]),}
}
</script>

<style lang="scss" scoped>

@import "src/assets/variables/variables";

@mixin grid {
  grid-template-columns: repeat(12, 1fr);
  column-gap: 9px;
}

.main-div-line {
  font-size: 10px;
  padding: 0.25rem;
  font-family: $font-primary-bold;
  border-bottom: 1px solid rgb(#E5E5E5, 0.5);
  border-right: 1px solid rgb(#E5E5E5, 0.5);
  border-top: 1px solid rgb(#E5E5E5, 0.3);
  border-left: 1px solid rgb(#E5E5E5, 0.5);
}

.second-div-line {
  display: grid;
  @include grid;
  margin-left: 6px;
  justify-content: space-between;
  font-family: $font-primary;
  padding-right: 1rem;
}

.label-line {
  display: grid;
  @include grid;
  padding: 0.5rem 1rem 0.5rem 0rem;
}

.name-label-line {
  grid-column: 1/ span 7;
  display: flex;
  align-items: end;
  cursor: pointer;
}

.expense-line {
  grid-column: 1/ span 7;
  display: flex;
  align-items: flex-start;
  max-height: 1rem;
  padding-top: 0.25rem;
}

.case-line {
  height: 14px;
  width: 14px;
  background-color: #D9D9D9;
  margin: 0.25rem;
  border-radius: 4px;
}

.svg-line-solo,
.svg-line-added,
.svg-line-adding {
  margin-right: 1rem;
}

.svg-line-added {
  position: relative;
  top: -20px;
}

.svg-line-adding {
  position: relative;
  top: -1px;
}

.svg-line-open {
  position: relative;
  margin-right: 1rem;
}

.input-expense {
  border: none;
  outline: none;
  font-family: $font-primary;
  font-size: 10px;
}

.new-data-div {
  grid-column: 1/ span 12;
  display: grid;
  @include grid;
}

.amount-anticipated,
.amount-expense-anticipated {
  grid-column: 10;
  font-size: 10px;
  text-align: end;
  color: grey;
}

.amount-real,
.amount-expense-real {
  grid-column: 12;
  text-align: end;
  cursor: pointer;
}

.new-expense {
  grid-column: 1/span 7;
}

.add-anticipated-expense {
  font-family: $font-primary;
  text-align: end;
  max-height: 1rem;
  grid-column: 10;
  width: 100%;
  align-self: end;
  border: 1px solid rgb(#D9D9D9, 0.7);
  font-size: 10px;
}

.add-real-expense {
  font-family: $font-primary;
  text-align: end;
  max-height: 1rem;
  grid-column: 12;
  width: 100%;
  align-self: end;
  border: 1px solid rgb(#D9D9D9, 0.7);
  font-size: 10px;
}

.first-line-div{
  grid-column: 12;
}

.update-amount{
  grid-column: 12;
}

.pencil-modification{
  max-height: 1rem;
  max-width: 1rem;
  margin-left:  0.5rem;
}

.modal-charge{
  font-family: $font-text;
  .title-modal{
    font-size: 16px;
    font-weight: bold;
  }
  div{
    display: grid;
    @include grid;
  }
  span,
  input,
  select{
    grid-column: 1/ span 12;
  }
  label{
    grid-column: 1/ span 3;
    font-size: 10px;
    position: relative;
    top: 10px;
    left: 10px;
    background-color: white;
    margin-top: 1rem;
  }
  input,
  select{
    font-family: $font-text;
    padding: 0.5rem 0.25rem;
    outline: none;
    border-radius: 4px;
    border: 1px solid rgb($color-grey-basic, 0.5);
  }

}

.validate-button{
  margin-top: 2rem;
}
</style>