<template>
<div>
  <span>Settings</span>
</div>
</template>

<script>
export default {
  name: "Settings"
}
</script>

<style scoped>

</style>