<template>
  <select class="select-input" v-model="selected">
    <option v-for="option in options" :key="option.index" :value="option.value">
      {{ option.text }}
    </option>
  </select>

</template>

<script>
export default {
  name: "SelectInput",
  props: {
    options: Array,
  },
  data() {
    return {
      selected: 1
    }
  }
}
</script>

<style scoped lang="scss">
@import "src/assets/variables/variables";

.select-input{
  padding: 0.25rem 0 0.25rem 0.5rem;
  border-radius: 16px;
  border: 1px solid $color-grey-basic ;
  font-family: $font-primary;
  outline: none;
}

</style>