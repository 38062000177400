<template>
<button>
 <IconLink class="test" :name="name"/>
 <span>{{buttonName}} </span>
</button>
</template>

<script>
import IconLink from "../../assets/icons/IconLink";
export default {
  name: "AddButton",
  components: {IconLink},
  props: {
    buttonName: String
  },
  data(){
    return{
      name: 'add'
    }
  }
}
</script>

<style scoped lang="scss">
@import "src/assets/variables/variables";

button {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $color-primary;
  font-family: $font-text;
  padding: 0.5rem 0.75rem;
  border-radius: 6px;
  color: white;
  border: none;
  cursor: pointer;
  margin-left: 1rem;
  font-size: 10px;
}

.test{
  fill: white;
}

</style>