<template>

  <div class="vue-modal" v-show="open">
    <div class="vue-modal-inner">
      <div class="vue-modal-content">
      <icon-link class="close-cross" name="close" @click="$emit('close')" />
        <slot />

      </div>
    </div>

  </div>
</template>

<script>
import IconLink from "../../assets/icons/IconLink";
export default {
  components: {IconLink},
  props: {
    open: {
      type: Boolean,
      required: true
    },
    data: {
      type: {},
      required: false,

    }
  }
}
</script>

<style scoped lang="scss">
*,
::before,
::after{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.vue-modal{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  background-color: rgba(0,0,0,0.02);
  z-index: 10;
}

.vue-modal-inner{
  max-width: 40%;
  background-color: white;
  border-radius: 6px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  top: 30%;
}

.vue-modal-content{
  padding:  2rem;
}

.close-cross{
  position: relative;
  right: 0;
  left: 95%;
  cursor: pointer;
}
</style>
