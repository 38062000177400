import { createStore } from "vuex";
import axios from "axios";


export default createStore({
    state: {
        charges: [],
        expenses: [],
        filter: {
            month: 1
        }
    },
    getters: {
        getCharges: (state) => state.charges,
        getExpenses: (state) => state.expenses,
        getFilters: (state) => state.filter
    },
    mutations: {
        SET_CHARGES(state, charges) {
            state.charges = charges;
        },
        SET_EXPENSES(state, expenses) {
            state.expenses = expenses;
        },
        UPDATE_CHARGE_LABEL(state, {type_id, id, label}) {
            const pos = state.charges.findIndex(function (type) {
                return type.id === type_id
            })
            const pos2 = state.charges[pos].charges.findIndex(function (charge) {
                return charge.id === id
            })
            state.charges[pos].charges[pos2].label = label
        },
    },

        actions: {
            async fetchCharges({commit}, month) {
                console.log('month : ' + month)
                try {
                    const data = await axios.get(`${process.env.VUE_APP_HOST_API_KARGA}charges`, {
                        params: {
                            filter: { month: month}
                        }
                    });
                    commit("SET_CHARGES", data.data);
                } catch (error) {
                    console.log(error);
                }
            },
            async fetchExpenses({commit}) {
                try {
                    const data = await axios.get(`${process.env.VUE_APP_HOST_API_KARGA}/expenses`);
                    commit("SET_EXPENSES", data.data);
                } catch (error) {
                    console.log(error);
                }
            },
            updateCharges({commit}, payload) {
                commit('SET_CHARGES', payload);
                this.dispatch('fetchCharges');
            },
            updateChargeLabel({commit}, data) {
                console.log(data);
              commit("UPDATE_CHARGE_LABEL", data);
            }
        }
    });
