<template>

<div class="main-div-dashboard">

  <div class="primary-part-dashboard">
    <div>
      <h2>Reporting Financier</h2>
    </div>
    <div class="buttons-adding">
      <AddButton @click="isOpen = true" :button-name="'Ajouter une charge'"/>
    </div>
  </div>
  <FilterBar @month="filter" />
  <div class="list-div" v-for="data in getCharges" :key="data.index">
    <List :month="month" :data="data" />
  </div>

  <ModalAdd class="modal-charge" :open="isOpen" @close="isOpen = !isOpen">
    <span class="title-modal">Charges</span>
    <div>
      <span>Gestion des charges</span>
      <label class="label-charge" for="charges">Libellé de la charge *</label>
      <input class="input-new-charge" name="charges" type="text" v-model="charges.label" placeholder="Nom de la nouvelle charge">
      <label class="label-type" for="type">Libellé du groupe*</label>
      <select class="select-type" v-model="charges.type_id">
        <option v-for="option in getCharges"   :key="option.index" :value="option.id">
          {{ option.label }}
        </option>
      </select>
      <base-button class="validate-button" @click="postCharge" button-name="Valider" />
    </div>
  </ModalAdd>
</div>
</template>

<script>
import List from '../components/lists/List';
import axios from 'axios';
import { mapGetters} from 'vuex';
import FilterBar from "../components/filters/FilterBar";
import AddButton from "../components/buttons/AddButton";
import ModalAdd from "../components/modals/ModalAdd";
import {ref} from "vue";
import BaseButton from "../components/buttons/BaseButton";

export default {
  name: "Dashboard",
  components: {BaseButton, ModalAdd, AddButton, FilterBar, List},
    data() {
      return {
        charges: {
          label: "",
          type_id: Number
        },
        fetchData: [],
        open: false,
        month: 1,
      }
    },
  setup(){
  const isOpen = ref(false);

  return { isOpen }
  },
  methods: {
    filter(e){
      console.log(e)
      this.month = e;
      //this.$store.dispatch("fetchCharges", this.month);
    },
    postCharge(){
      axios.post(`https://preprod.karga.green-conseil.com/api/charges/`, this.charges)
          .then( response => console.log(response), this.$store.dispatch("fetchCharges", this.month))
          .catch(error => console.log(error))
      this.isOpen = !this.isOpen
      this.$store.dispatch("fetchCharges", this.month);
    },
   async loadData(){
      this.fetchData = this.$store.state.charges
    },
  },
  created() {
    this.$store.dispatch("fetchCharges", this.month);
  },
  watch: {
    month () {
      this.$store.dispatch("fetchCharges", this.month)
    }
  },
  computed: {
    ...mapGetters([
      'getCharges'
    ]),}
}
</script>

<style scoped lang="scss">
@import "../assets/variables/variables";

@mixin grid {
  grid-template-columns: repeat(12, 1fr);
  column-gap: 9px;
}

h2{
  font-size: 14px;
}
.main-div-dashboard {
  @include grid;
  display: grid;
  overflow: auto;
  height: 90vh;
}

.list-div {
  grid-column: 1 / span 12;
}

.buttons-adding{
  display: flex;
}
.primary-part-dashboard{
  grid-column: 1/span 12;
  margin: 0.5rem 1rem;
  display: flex;
  justify-content: space-between;
  h2{
    font-family: $font-text;
  }
}

.modal-charge{
  font-family: $font-text;
  .title-modal{
    font-size: 16px;
    font-weight: bold;
  }
  div{
    display: grid;
    @include grid;
  }
  span,
  input,
  select{
    grid-column: 1/ span 12;
  }
  label{
    grid-column: 1/ span 3;
    font-size: 12px;
    position: relative;
    top: 10px;
    left: 10px;
    background-color: white;
    margin-top: 1rem;
  }
  input,
  select{
    font-family: $font-text;
    padding: 0.5rem 0.25rem;
    outline: none;
    border-radius: 4px;
    border: 1px solid rgb($color-grey-basic, 0.5);
  }

}

.validate-button{
  margin-top: 2rem;
  grid-column: 11/ span 2;
}

</style>


