<template>
  <button>
    <span>{{buttonName}} </span>
  </button>

</template>

<script>
export default {
  name: "BaseButton",
  props: {
    buttonName: String
  },
}
</script>

<style scoped lang="scss">
@import "src/assets/variables/variables";

button {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $color-primary;
  font-family: $font-text;
  padding: 0.5rem 0.75rem;
  border-radius: 6px;
  color: white;
  border: none;
  cursor: pointer;
  margin-left: 1rem;
}


</style>