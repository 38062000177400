<template>
  <div class="main-app-div" @click="toogle">
    <p class="ariane-filler"> <span class="ariane-greef">GREEF > </span>{{currentRouteName}} </p>
    <LeftNavigation :drop-down-app-props="closeDropdown" :drop-down-avatar-props="closeDropdown"   />
    <router-view class="pages" />
  </div>

</template>


<script>
import LeftNavigation from "./components/navigations/LeftNavigation";


export default {
  name: 'App',
  components: {
    LeftNavigation,
  },
  data(){
  return {
    closeDropdown: true,
    apiTesting: [],

  }
  },
  methods: {
  toogle(){
    this.closeDropdown = !this.closeDropdown;
  }
  },
  computed: {
    currentRouteName() {
      return this.$route.name;
    },
  }
}
</script>

<style lang="scss">
@import url("./assets/fonts/fonts.scss");
@import "assets/variables/variables";

* {
  margin: 0;
  padding: 0;
}

body{
  margin: 0;
  padding: 0;
}

#app {
  font-family: $font-primary;
  text-align: left;
}

.ariane-filler{
  font-size: 12px;
  margin-left: 5rem;
  margin-top: 0.5rem;
  text-transform: uppercase;
  font-family: $font-text;
}
.pages{
  margin-left: 70px;
  height: 80vh;
}
.ariane-greef{
  color: grey;
}
</style>
