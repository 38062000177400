<template>
<div>
<img src="./avatar.png" />
</div>
</template>

<script>
export default {
  name: "Avatar_logo.vue"
}
</script>

<style lang="scss" scoped>
@import "../variables/variables";

div{
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0.5rem;
  width: 3rem;
  height: 3rem;
  border-radius: 8px;
  border: 1px solid $color-border-avatar;
}
</style>