<template>
  <div class="main-div-list">
    <div class="group-charges">
      <div class="group-name-number-div">
        <span class="label-title">{{data.label}} </span>
        <span class="number-charges"> {{data.charges.length}} charges </span>
      </div>
        <span class="estimate-amount">Montant estimé HT</span>
        <span class="real-amount">Montant validé HT</span>



    </div>

    <Line :month="month" :data="data" />
    <div></div>
   </div>


</template>

<script>

import Line from "./Line";
export default {
  name: "List",
  components: { Line},
  props: {
    data: {},
    month: null
  }
}

</script>

<style scoped lang="scss">
@import "src/assets/variables/variables";
@mixin grid {
  grid-template-columns: repeat(12, 1fr);
  column-gap: 9px;
}

.main-div-list{
  margin: 1rem;
  border-bottom: 1px solid rgb(#E5E5E5, 0.8);
  font-size: 12px;

}

.label-title{
  background-color: #7986CB;
  color: white;
  border-radius: 4px 4px 0px 0px;
  padding: 0.25rem;
  display: flex;
justify-content: center;
  align-items: center;

}

.group-charges{
display: grid;
  @include grid;
  padding-right: 1rem;
}
.number-charges{
  margin-left: 1rem;
  font-size: 12px;
  font-weight: bold;
}

.group-name-number-div{
  grid-column: 1/ span 7;
  display: flex;
  align-items: center;
}

.estimate-amount{
  grid-column: 10;
  text-align: end;
  font-weight: bold;
  font-size: 10px;
}
.real-amount{
  grid-column: 12;
  text-align: end;
  font-weight: bold;
  font-size: 10px;
}
.amounts-div{
  font-weight: bold;
  span:first-child{
    margin-right: 3.5rem;
  }
  span{
    margin: 0rem 1rem;
  }
}

</style>