<template>
  <div class="main-div-left-navigation" v-bind:class="{ thinBar: !isActive, largeBar: isActive }">
    <div class="top-div-left-navigation">
      <GREEN_logo/>
      <left-navigation-link class="navigation-link" name="search"/>
      <router-link active-class="activeLink" to="/new">
        <LeftNavigationLink class="navigation-link" name="add"/>
      </router-link>
    </div>


    <div class="middle-div-left-navigation">
      <div>
        <router-link active-class="activeLink" to="/dashboard">
          <LeftNavigationLink class="navigation-link" name="dashboard"/>
        </router-link>
        <router-link active-class="activeLink" to="/settings">
          <LeftNavigationLink class="navigation-link" name="paramètres"/>
        </router-link>
      </div>

      <div>
        <LeftNavigationLink @mouseover="dropDownApps = true"  class="navigation-link" name="apps"/>
        <router-link active-class="activeLink" to="/params">
          <LeftNavigationLink class="navigation-link" name="params"/>
        </router-link>
        <a href="https://greenconseilcom-my.sharepoint.com/:w:/g/personal/ophelie_rochefeuille_green-conseil_com/EZq4z0XEJvpIoQWZVT2-UW8BG6WRu31P4FqpyPXohQ94qQ?e=4Uq89I">
          <LeftNavigationLink class="navigation-link" name="support"/>
        </a>
      </div>
    </div>

    <div class="bottom-div-left-navigation">
      <Avatar_logo class="avatar-logo" @mouseover="dropDownAvatar = true"/>
    </div>

  </div>

  <DropDownMenu v-if="dropDownApps === true" @mouseleave="dropDownApps = false" class="dropDownApps">
    <template v-slot:first_content>
      <ul>
        <li>Chewie</li>
        <li>Adelle</li>
        <li>Padme</li>
      </ul>
    </template>
  </DropDownMenu>

  <DropDownMenu v-if="dropDownAvatar === true" @mouseleave="dropDownAvatar = false" class="dropDownAvatar">
    <template v-slot:first_content>
      <div class="main-div-avatar-menu">
        <div class="top-div-avatar-menu">
          <Avatar_logo/>
          <div class="div-names-avatar">
            <p>GREEF Karga</p>
            <a href="#">Accéder à mon profil</a>
          </div>

        </div>

      </div>

      <div class="bottom-div-avatar-menu">
        <ButtonMenu class="button-avatar" name="time" title-button="Historique"></ButtonMenu>
        <ButtonMenu class="button-avatar" name="logout" title-button="Déconnexion"></ButtonMenu>

      </div>
      <span class="version-app">v{{ version }} GREEF</span>

    </template>
  </DropDownMenu>

</template>

<script>
import GREEN_logo from "../../assets/icons/GREEN_logo";
import Avatar_logo from "../../assets/icons/Avatar_logo";
import LeftNavigationLink from "./LeftNavigationLink";
import DropDownMenu from "../menus/DropDownMenu";
import ButtonMenu from "../buttons/ButtonMenu";


export default {
  name: "LeftNavigation",
  components: {ButtonMenu, DropDownMenu, Avatar_logo, LeftNavigationLink, GREEN_logo},
  props: {
    dropDownAvatarProps: {
      type: Boolean,
      default: false
    },
    dropDownAppProps: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    wrapper() {
      this.isActive = !this.isActive;
    },
    wrapMenu() {
      this.dropDownAvatar = !this.dropDownAvatar;
    },
    wrapMenuApps() {
      this.dropDownApps = !this.dropDownApps;
    },
  },
  data() {
    return {
      isActive: false,
      dropDownAvatar: false,
      dropDownApps: false,
      version: process.env.VUE_APP_VERSION
    };
  },
}
</script>

<style lang="scss" scoped>
@import "src/assets/variables/variables";

.main-div-left-navigation {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  top: 0;
  width: 66px;
  height: 100vh;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 1px 3px rgba(0, 0, 0, 0.12);
}

.navigation-link,
.button-avatar,
.avatar-logo{
  cursor: pointer;
  &:hover {
    background-color: $color-hover;
  }
}

a.activeLink {
  width: 100%;
  div {
    background-color: $color-active-link;
    border-right: 2px solid $color-primary;
  }
}

.largeBar {
  width: 12.5rem;
}

.top-div-left-navigation {
  border-bottom: 1px solid $color-border-bar;
}

.bottom-div-left-navigation {
  border-top: 1px solid $color-border-bar;
}

.middle-div-left-navigation {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.largeBar,
.thinBar {
  transition: all 0.5s;
}

.top-div-avatar-menu {
  display: flex;
  align-items: center;

  .div-names-avatar {
    display: flex;
    flex-direction: column;

    p {
      text-align: initial;
      margin: 0.2rem 0rem
    }
  }

}

.main-div-avatar-menu {
  border-bottom: 1px solid $color-border-bar;
  a {
    text-align: left;
    text-decoration: underline;
    color: $color-grey-basic;
    font-weight: bold;
    font-size: 12px;
  }

}

.version-app {
  color: $color-grey-basic;
  font-size: 12px;
  position: initial;
}

.dropDownAvatar {
  text-align: left;
}

.dropDownApps{
  width: 9.5rem;
  margin-bottom: 9.5rem;
  ul{
    padding: 0.5rem;
    margin: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  li{
    list-style-type: none;
    padding: 0.2rem 0rem;
    cursor: pointer;
    &:hover {
      background-color: $color-hover;
    }
  }
}
</style>
