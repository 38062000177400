<template>
  <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="12" height="12" rx="4" fill="#D9D9D9"/>
  </svg>

</template>

<script>
export default {
  name: "svg_line_solo"
}
</script>

<style scoped>

</style>