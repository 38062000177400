import { createRouter, createWebHistory } from 'vue-router';

import Dashboard from "../views/Dashboard";
import NewData from "../views/NewData";
import Settings from "../views/Settings";
import Params from "../views/Params";

const routes = [
    {
        path: "/",
    redirect: "/dashboard",
    },
    {
        path:'/dashboard',
        name: 'Dashboard',
        component: Dashboard
    },
    {
        path:'/new',
        name: 'New',
        component: NewData
    },
    {
        path:'/settings',
        name: 'Settings',
        component: Settings
    },
    {
        path:'/params',
        name: 'Params',
        component: Params
    }

]

const router = createRouter({
    history: createWebHistory(),
    routes
})

export default router