<template>
<div>
  <icon-link class="icon-link" :name="name"></icon-link>
  <span class="title">{{titleButton}}</span>
</div>
</template>

<script>
import IconLink from "../../assets/icons/IconLink";

export default {
  name: "buttonMenu",
  components: { IconLink },
  props: {
    name: {
      type: String
    },
    titleButton: {
      type: String
    }
  },
}
</script>

<style lang="scss" scoped>

div{
  display: flex;
  justify-content: space-between;
  padding: 0.5rem;
}

</style>